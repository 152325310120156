import {
  Card,
  CardContent,
  Divider,
  Typography,
  Stack,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import TimeFilter from "../common/TimeFilter";
import MovingIcon from "@mui/icons-material/Moving";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import { getToken } from "../../utils/GetToken";
import { GetUserStatsServices } from "../../services/GetUserStatsServices";
import { useState } from "react";

export default function UserAccountsResume() {
  const { t } = useTranslation();
  const token = getToken();
  const [selectedMonth, setSelectedMonth] = useState("");

  const getUserStats = async () => {
    const response = await GetUserStatsServices(token);
    return response.data;
  };

  const {
    data: response,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["userStats"],
    queryFn: () => getUserStats(),
  });

  const usersStats = response?.data || [];
  const totalUsers = usersStats.reduce((total, user) => total + user.count, 0);
  const percentage = (totalUsers * totalUsers) / 100;

  const availableMonths = [...new Set(usersStats.map((user) => user.month))];

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  const getCountForSelectedMonth = () => {
    const selectedUserStats = usersStats.find(
      (user) => user.month === selectedMonth
    );
    return selectedUserStats ? selectedUserStats.count : 0;
  };

  return (
    <Card elevation={5} sx={{ borderRadius: "20px" }}>
      <CardContent>
        <Stack direction="row" spacing={2} alignItems="baseline">
          <Typography variant="h6">{t("accountsCard.title-1")}</Typography>
          <TimeFilter />
        </Stack>
        <Divider sx={{ my: "1rem" }} />
        <Typography>{t("accountsCard.subtitle-4")}</Typography>
        <Stack direction="row" spacing={1} alignItems="baseline" my={1}>
          <Typography variant="h5" fontWeight={600}>
            {isLoading || isError ? "-" : totalUsers}
          </Typography>
          <Typography variant="p">{t("registered")}</Typography>
        </Stack>
        <Stack direction="row" spacing={0.5} alignItems="center">
          <MovingIcon color="success" />
          <Typography variant="subtitle2" color={"success.main"}>
            ({percentage}%)
          </Typography>
        </Stack>
        <Divider sx={{ my: "1rem" }} />
        <Typography>{t("accountsCard.subtitle-5")}</Typography>

        <Stack direction="row" spacing={2} alignItems="center" mt="1rem">
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <InputLabel>{t("months")}</InputLabel>
            <Select
              value={selectedMonth}
              onChange={handleMonthChange}
              displayEmpty
              label={t("months")}
            >
              {availableMonths.map((month) => (
                <MenuItem key={month} value={month}>
                  {t("months") + " " + t(month)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Mostrar el valor de count para el mes seleccionado */}
          {selectedMonth && (
            <Stack direction="row" spacing={0.5} alignItems="center">
              <GroupAddIcon color="success" />
              <Typography variant="h5" color={"success.main"}>
                {isLoading || isError ? "-" : getCountForSelectedMonth()}
              </Typography>
            </Stack>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
}
