import { Fragment, useState, useEffect, useCallback, useRef } from "react";
import { Grid, Typography, Box, Snackbar, Alert } from "@mui/material";
import { useMenu } from "../../context/MenuOpenContext";
import { Content } from "../../utils/Content";
import {
  GoogleMap,
  LoadScript,
  useJsApiLoader,
  Marker,
  InfoWindowF,
  Polygon,
} from "@react-google-maps/api";

const VITE_API_KEY_MAP = import.meta.env.VITE_API_KEY_MAP;

const containerStyle = {
  width: "80vw",
  height: "80vh",
};

export default function MapContainer({
  markers,
  zoomMap,
  centerMap,
  path,
  keyValue,
}) {
  const optionsMaps = {
    scrollwheel: true,
    center: centerMap,
    zoom: zoomMap,
  };

  const [activeMarker, setActiveMarker] = useState(null);

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: VITE_API_KEY_MAP,
  });

  return (
    <>
      {isLoaded ? (
        <GoogleMap
          mapContainerStyle={containerStyle}
          // onLoad={onLoad}
          // onUnmount={onUnmount}
          options={optionsMaps}
          onClick={() => setActiveMarker(null)}
        >
          {/*  <Polygon
            path={path}
            key={1}
            options={{
              fillColor: "#FF0000",
              fillOpacity: 0.4,
              strokeColor: "#FF0000",
              strokeOpacity: 1,
              strokeWeight: 2,
            }}
            onClick={() => {
              console.log("ahmet");
            }}
          /> */}

          <Polygon
            options={{
              fillColor: "#FF0000",
              fillOpacity: 0.2,
              strokeColor: "#FF0000",
              strokeOpacity: 0.2,
              strokeWeight: 0.5,
            }}
            key={keyValue}
            paths={path}
            /*   paths={[
              [
                {lat:4.657338738611306,lng:-74.1258761824266},
                {lat:4.653739960816096,lng:-74.10863960263012},
                {lat:4.624404666691717,lng:-74.13577772310664},
                {lat:4.685368341694883,lng:-74.07528432359432}
              ]
            ]} */
          />
          {markers.map(({ _id, nombre, position, icono }) => (
            <Marker
              key={_id}
              position={position}
              onClick={() => handleActiveMarker(nombre)}
              icon={{
                url: icono,
                //scaledSize: new google.maps.Size(60, 60),
              }}
              // icon={{
              //   url:"https://t4.ftcdn.net/jpg/02/85/33/21/360_F_285332150_qyJdRevcRDaqVluZrUp8ee4H2KezU9CA.jpg",
              //   scaledSize: { width: 50, height: 50 }
              // }}
            >
              {activeMarker === nombre ? (
                <InfoWindowF onCloseClick={() => setActiveMarker(null)}>
                  <div>
                    <p>{nombre}</p>
                  </div>
                </InfoWindowF>
              ) : null}
            </Marker>
          ))}

          <Marker
            key={100}
            position={centerMap}
            onClick={() => handleActiveMarker(100n)}
            icon={{
              url: "/flag.png",
              //scaledSize: new google.maps.Size(60, 60),
            }}
            // icon={{
            //   url:"https://t4.ftcdn.net/jpg/02/85/33/21/360_F_285332150_qyJdRevcRDaqVluZrUp8ee4H2KezU9CA.jpg",
            //   scaledSize: { width: 50, height: 50 }
            // }}
          ></Marker>

          <></>
        </GoogleMap>
      ) : (
        <></>
      )}
    </>
  );
}
