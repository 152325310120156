import { Grid, Typography, Stack, Box, Divider } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import  AgentsAccountsResume from "../AgentsAccountsResume";
import ComerceTops from "../ComerceTops";

export default function AgentsAccountsLayout() {
  const { t } = useTranslation();

  return (
    <Box className="content" sx={{ ml: { xs: "0", sm: "1rem" }, mt: "0"}}>
      <Typography component='div' variant="h4" fontWeight={600}>
        {t("accountsResume.title")}
      </Typography>
      <Divider sx={{ my: "1rem" }} />
      <Grid container gap={"2rem"} alignItems="center" justifyContent="center">
        <Grid item>
          <Stack direction={{ xs: "columns", md: "row" }} mt={2} gap={"3rem"}>
            <AgentsAccountsResume />
            <ComerceTops />
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
}