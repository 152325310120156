import { Card, CardContent, Divider, Typography, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import TimeFilter from "../common/TimeFilter";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import HorizontalRuleRoundedIcon from "@mui/icons-material/HorizontalRuleRounded";

export default function ComerceTops() {
  const { t } = useTranslation();

  return (
    <Card elevation={5} sx={{ borderRadius: "20px" }}>
      <CardContent>
        <Stack direction="row" spacing={2} alignItems="baseline">
          <Typography variant="h6">{t("planTopCard.title")}</Typography>
          <TimeFilter />
        </Stack>
        <Divider sx={{ my: "1rem" }} />
        <Stack>
          <Typography
            sx={{ display: "flex", alignItems: "center", height: "35px" }}
          >
            1.
            <ArrowDropUpIcon sx={{ fontSize: 50, color: "success.main", ml: "0.2rem" }} />
            Tienda el vecino
          </Typography>
          <Typography
            sx={{ display: "flex", alignItems: "center", height: "35px" }}
          >
            2.
            <ArrowDropDownIcon sx={{ fontSize: 50, color: "error.main" }} />
            
            Verduras doña Nelly
          </Typography>
          <Typography
            sx={{ display: "flex", alignItems: "center", height: "35px" }}
          >
            3.
            <HorizontalRuleRoundedIcon
              sx={{ fontSize: 50, color: "grays.light" }}
            />
            Greta Rice
          </Typography>
          <Typography
            sx={{ display: "flex", alignItems: "center", height: "35px" }}
          >
            4.
            <ArrowDropUpIcon sx={{ fontSize: 50, color: "success.main" }} />
            
            Max Lesch
          </Typography>
          <Typography
            sx={{ display: "flex", alignItems: "center", height: "35px" }}
          >
            5.
            <ArrowDropUpIcon sx={{ fontSize: 50, color: "success.main" }} />
            
            La tienda de doña Kriztina
          </Typography>
        </Stack>
      </CardContent>
    </Card>
  );
}
