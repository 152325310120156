export const zoneDetailsMapping = (data) => {

  const zoneMapped = {
    id: data.id,
    name: data.name,
    location: data.location,
    country: data?.country?.name,
    department: data?.department?.name,
    city: data?.city?.name,
    center: data?.geo_area?.coordinates[0][0],
    coordinates: data?.geo_area?.coordinates[0],
  };
  
  return zoneMapped;
}