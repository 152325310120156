import { useCallback, useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Grid,
  TextField,
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Snackbar,
  Alert,
  Typography,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { getToken } from "../../utils/GetToken";
import { CreateNewZoneService } from "../../services/Zones/CreateNewZoneService";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation } from "@tanstack/react-query";
import { GetCountriesService } from "../../services/DictionaryLists/GetCountriesService";
import { DepartmentListByCountryService } from "../../services/DictionaryLists/DepartmentListByCountryService";
import { CitiesListByDepartmentService } from "../../services/DictionaryLists/CitiesListByDepartmentService";
import MapZoneSelector from "./MapZoneSelector";
import { useZone } from "../../context/ZonesContext";
import { GetZoneDetailService } from "../../services/Zones/GetZoneDetailService";
import { useParams, useNavigate } from "react-router-dom";
import { zoneEditMapping } from "../../adapters/zones/zoneEditMapping";
import { UpdateZoneService } from "../../services/Zones/UpdateZoneService";

export default function CreateNewZoneForm({ mode }) {
  const [formValues, setFormValues] = useState({
    name: "",
    location: "",
    country: "",
    department: "",
    city: "",
  });
  const [formValuesEdit, setFormValuesEdit] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingZone, setLoadingZone] = useState(true);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const {
    handleZoneCreated,
    handleZoneUpdated,
    isZoneUpdated,
    resetZonesStates,
    handleCenterZone,
    handleZoneCoordinates,
  } = useZone();
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [zoneDetail, setZoneDetail] = useState({});

  const { zoneId } = useParams();

  const { t } = useTranslation();

  const navigate = useNavigate();

  const getCountries = async () => {
    const response = await GetCountriesService();
    return response;
  };

  const getDepartments = async () => {
    if (formValues.country !== "") {
      const token = await getToken();
      const response = await DepartmentListByCountryService(
        token,
        formValues.country
      );
      return response;
    }
    return [{}];
  };

  const getCities = async () => {
    if (formValues.department !== "") {
      const token = await getToken();
      const response = await CitiesListByDepartmentService(
        token,
        formValues.department
      );
      return response;
    }
    return [{}];
  };

  const getZone = async () => {
    const token = await getToken();
    if (token) {
      const response = await GetZoneDetailService(token, zoneId);
      console.log(response);
      // setLoadingZone(false);
      return response;
    }
    return {};
  };

  const countries = useQuery({
    queryKey: ["countries"],
    queryFn: getCountries,
  });

  const departments = useQuery({
    queryKey: ["departments"],
    queryFn: getDepartments,
  });

  const cities = useQuery({
    queryKey: ["cities"],
    queryFn: getCities,
  });

  const zone = useQuery({
    queryKey: ["zone"],
    queryFn: getZone,
    enabled: ["edit", "editAvailable"].includes(mode),
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if (["edit", "editAvailable"].includes(mode)) {
      setFormValuesEdit((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleCountryChange = (event, value) => {
    setFormValues((prevState) => ({
      ...prevState,
      country: value.id,
    }));
    if (["edit", "editAvailable"].includes(mode)) {
      setFormValuesEdit((prevState) => ({
        ...prevState,
        country: value.id,
      }));
      setSelectedDepartment(null);
      setSelectedCity(null);
    }
    setSelectedCountry(value);
  };

  const handleDepartmentChange = (event, value) => {
    setFormValues((prevState) => ({
      ...prevState,
      department: value.id,
    }));
    if (["edit", "editAvailable"].includes(mode)) {
      setFormValuesEdit((prevState) => ({
        ...prevState,
        country: selectedCountry?.id,
        department: value.id,
      }));
      setSelectedCity(null);
    }
    setSelectedDepartment(value);
  };

  const handleCityChange = (event, value) => {
    setFormValues((prevState) => ({
      ...prevState,
      city: value.id,
    }));
    if (["edit", "editAvailable"].includes(mode)) {
      setFormValuesEdit((prevState) => ({
        ...prevState,
        country: selectedCountry?.id,
        department: selectedDepartment?.id,
        city: value.id,
      }));
    }
    setSelectedCity(value);
  };

  const handleZoneChange = (layerType, value) => {
    if (layerType === "Polygon") {
      const formattedCoordinates = value.map((coord) => {
        const [latitude, longitude] = coord;
        return { latitude, longitude };
      });
      setFormValues((prevState) => ({
        ...prevState,
        geo_area: formattedCoordinates,
      }));
      if (["edit", "editAvailable"].includes(mode)) {
        setFormValuesEdit((prevState) => ({
          ...prevState,
          geo_area: formattedCoordinates,
        }));
      }
    } else if (layerType === "Point") {
      const formattedCoordinate = {
        latitude: value[0],
        longitude: value[1],
      };
      setFormValues((prevState) => ({
        ...prevState,
        geo_point: formattedCoordinate,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const token = await getToken();
      if (["edit", "editAvailable"].includes(mode)) {
        console.log(formValuesEdit);
        const response = await UpdateZoneService(token, formValuesEdit, zoneId);
        console.log(response);
        handleZoneUpdated();
      } else {
        console.log(formValues);
        const response = await CreateNewZoneService(token, formValues);
        console.log(response);
        handleZoneCreated();
      }
    } catch (error) {
      console.log(error);
      setError(true);
      setErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    departments.refetch();
  }, [formValues?.country]);

  useEffect(() => {
    cities.refetch();
  }, [formValues?.department]);

  const setZoneData = useCallback(() => {
    if (["edit", "editAvailable"].includes(mode) && zone.isSuccess) {
      const zoneMapped = zoneEditMapping(zone.data);
      setFormValues({
        country: zoneMapped?.country?.id,
        department: zoneMapped?.department?.id,
        city: zoneMapped?.city?.id,
        name: zoneMapped?.name,
        location: zoneMapped?.location,
        geo_area: zoneMapped?.coordinates,
        geo_point: zoneMapped?.center,
      });
      handleCenterZone(zoneMapped?.center);
      handleZoneCoordinates(zoneMapped?.coordinates);
      setSelectedCountry(zone?.data?.country);
      setSelectedDepartment(zoneMapped?.department);
      setSelectedCity(zoneMapped?.city);
    }
  }, [zoneDetail]);

  useEffect(() => {
    setZoneData();
  }, [setZoneData]);

  useEffect(() => {
    zone.isLoading ? setLoading(true) : setLoading(false);
    setZoneDetail(zone.data);
  }, [zone]);

  useEffect(() => {
    if (["edit", "editAvailable"].includes(mode) && formValues.name === "") {
      zone.refetch();
    }
  }, [formValues]);

  const handleCloseError = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setError(false);
  };

  useEffect(() => {
    if (isZoneUpdated) {
      const redirectTimer = setTimeout(() => {
        navigate(mode === "editAvailable"
        ? "/listManagement?tab=6"
        : "/zones");
      }, 3000);

      return () => clearTimeout(redirectTimer);
    }
    finalizeUpdateZone();
  }, [isZoneUpdated]);

  const handleCloseUpdateAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    handleZoneUpdated();
  };

  const finalizeUpdateZone = () => {
    resetZonesStates();
  };

  useEffect(() => {
    setFormValues((prevState) => ({
      ...prevState,
      zoneCoverage: mode == "available",
      zoneSafe: mode == "new",
    }));
  }, [mode, formValues?.name]);

  return (
    <Card
      elevation={4}
      sx={{
        borderRadius: "20px",
        mt: { xs: "1rem", sm: 0 },
        p: { xs: "0.4rem", sm: "2rem" },
        maxWidth: "100%",
      }}
    >
      <Snackbar
        open={error}
        autoHideDuration={6000}
        onClose={handleCloseError}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="error">{errorMessage}</Alert>
      </Snackbar>
      <Snackbar
        open={isZoneUpdated}
        autoHideDuration={6000}
        onClose={handleCloseUpdateAlert}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="success">{t("zones.edit.success.message")}</Alert>
      </Snackbar>
      <CardContent sx={{ mt: { xs: "1rem", sm: "0rem" } }}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                name="name"
                label={t("zones.formLabel.name")}
                value={formValues.name}
                onChange={handleInputChange}
                size="small"
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                name="location"
                label={t("zones.formLabel.location")}
                value={formValues.location}
                onChange={handleInputChange}
                size="small"
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              {!countries.isFetching && (
                <Autocomplete
                  id="country-select"
                  options={countries.isSuccess ? countries.data : []}
                  loadingText="Loading..."
                  onChange={handleCountryChange}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  disableClearable
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      {option.name}
                    </Box>
                  )}
                  value={selectedCountry}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      label={t("zones.table.header-3")}
                      required
                      value={selectedCountry}
                    />
                  )}
                />
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              {formValues.country !== "" && !departments.isFetching && (
                <Autocomplete
                  id="department-select"
                  options={departments?.data}
                  loadingText="Loading..."
                  onChange={handleDepartmentChange}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                  disableClearable
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      {option.name}
                    </Box>
                  )}
                  value={selectedDepartment}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      label={t("zones.table.header-4")}
                      required
                      // value={selectedDepartment}
                    />
                  )}
                />
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              {formValues.department !== "" && !cities.isFetching && (
                <Autocomplete
                  id="city-select"
                  options={cities?.data}
                  loadingText="Loading..."
                  onChange={handleCityChange}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                  disableClearable
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      {option.name}
                    </Box>
                  )}
                  value={selectedCity}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      label={t("zones.table.header-5")}
                      required
                      // value={selectedCity}
                    />
                  )}
                />
              )}
            </Grid>
          </Grid>
          <Box mt={2}>
            <Box my={2}>
              <Typography variant="caption">
                {t("zones.createNew.map.description")}
              </Typography>
            </Box>
            <MapZoneSelector handleZoneChange={handleZoneChange} mode={mode} />
          </Box>
          <Box mt={"2rem"} sx={{ textAlign: "center" }}>
            <Button
              type="submit"
              variant="contained"
              color={"secondary"}
              size="large"
              disabled={formValues.city === ""}
              startIcon={
                loading ? (
                  <CircularProgress size="1.5rem" sx={{ color: "white" }} />
                ) : (
                  <CheckCircleIcon sx={{ color: "white" }} />
                )
              }
            >
              {["edit", "editAvailable"].includes(mode)
                ? t("createNewPlan.button.edit")
                : t("createNewPlan.button.create")}
            </Button>
          </Box>
        </form>
      </CardContent>
    </Card>
  );
}
