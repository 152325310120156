import React, { useState, useEffect, useRef } from "react";
import {
  MapContainer,
  TileLayer,
  useMapEvents,
  FeatureGroup,
  useMap,
  Polygon,
  Marker,
  Popup,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import osm from "../../utils/Maps/osm-provider";
import { EditControl } from "react-leaflet-draw";
import { useZone } from "../../context/ZonesContext";
import { useTranslation } from "react-i18next";
import L from "leaflet";

function getIcon(_iconSize) {
  return L.icon({
    iconUrl: ("/pointerUser.png"),
    iconSize: [_iconSize],
  })
}

const MapPlaceholder = () => {
  return (
    <p>
      <noscript>You need to enable JavaScript to see this map.</noscript>
    </p>
  );
};

const options = { color: "blue" };

const MapZoneSelector = ({ handleZoneChange, mode }) => {
  const [geojsonData, setGeojsonData] = useState(null);
  const { centerZone, zoneCoordinates } = useZone();
  const [center, setCenter] = useState([0, 0]);
  const [zoom, setZoom] = useState(4);
  const mapRef = useRef();
  const { t } = useTranslation();

  const handleDrawCreated = (e) => {
    const { layerType, layer } = e;
    if (layerType === "polygon" || layerType === "marker") {
      const geojson = layer.toGeoJSON();
      setGeojsonData(geojson);
    }
  };

  const ClearMap = () => {
    const map = useMapEvents({
      click: () => {
        setGeojsonData(null);
      },
    });
    return null;
  };

  const LocationMarker = () => {
    const [position, setPosition] = useState(null);

    const map = useMap();

    useEffect(() => {
      if (geojsonData === null) {
        map.locate().on("locationfound", function (e) {
          setPosition(e.latlng);
          map.flyTo(e.latlng, 15);
        });
      }
    }, []);

    return position === null ? null : (
      <Marker position={position} icon={getIcon(38)}>
        <Popup>{t("zones.create.popup.title")}</Popup>
      </Marker>
    );
  };

  const ChangeView = ({ center, zoom }) => {
    const map = useMap();
    if (center && zoom) {
      map.setView(center, zoom);
    }
    return null;
  };

  const PolygonSafeZone = () => {
    if (mode === "new" || mode === "available") return null;
    return (
      zoneCoordinates && (
        <Polygon pathOptions={options} positions={zoneCoordinates} />
      )
    );
  };

  useEffect(() => {
    const layerType = geojsonData?.geometry?.type;
    const coordinates =
      layerType === "Point"
        ? geojsonData?.geometry?.coordinates
        : geojsonData?.geometry?.coordinates[0];
    if (mode !== "details") {
      handleZoneChange(layerType, coordinates);
    }
  }, [geojsonData]);

  useEffect(() => {
    setCenter(centerZone);
    setZoom(15);
  }, [centerZone]);

  return (
    <MapContainer
      center={center}
      zoom={zoom}
      style={{ height: "400px", width: "100%" }}
      ref={mapRef}
      placeholder={<MapPlaceholder />}
    >
      {/* <ClearMap /> */}
      {["edit", "editAvailable", "details"].includes(mode) && (
        <>
          <ChangeView center={center} zoom={zoom} />
          <PolygonSafeZone />
        </>
      )}
      {(mode === "new" || mode === "available") && <LocationMarker />}
      <TileLayer
        url={osm.maptiler.url}
        attribution={osm.maptiler.attribution}
      />
      <FeatureGroup>
        {mode !== "details" && (
          <EditControl
            position="topright"
            onCreated={handleDrawCreated}
            draw={{
              rectangle: false,
              circle: false,
              circlemarker: false,
              polyline: false,
              marker: false,
            }}
          />
        )}
      </FeatureGroup>
    </MapContainer>
  );
};

export default MapZoneSelector;
