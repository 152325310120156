import React, { useState } from "react";
import {
  Card,
  CardContent,
  Divider,
  Typography,
  Stack,
  Select,
  MenuItem,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Line } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { GetTransactionsService } from "../../services/GetTransactionsService";
import TimeFilter from "../common/TimeFilter";
import { getToken } from "../../utils/GetToken";

export default function AmountPerPeriod() {
  const [selectedMonth, setSelectedMonth] = useState("");
  const { t } = useTranslation();
  const token = getToken();

  const getTransactions = async () => {
    const response = await GetTransactionsService(token);
    console.log(response.data);
    return response.data;
  };

  const { data: transactions, isLoading, isError } = useQuery({
    queryKey: ["transactions"],
    queryFn: () => getTransactions(),
  });

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
        position: "bottom",
      },
    },
  };

  const renderMonthOptions = () => {
    if (!transactions) return null;
    return Object.keys(transactions.data).map((key) => (
      <MenuItem key={key} value={key}>
        {key}
      </MenuItem>
    ));
  };

  const graphicData = () => {
    if (!transactions) return null;

    const selectedMonthData = selectedMonth ? transactions.data[selectedMonth] : null;
    const data = selectedMonthData || {
      realizadas: 0,
      aprobadas: 0,
      rechazadas: 0,
    };

    return {
      labels: [t("made"), t("approved"), t("rejected")],
      datasets: [
        {
          data: [
            data.realizadas,
            data.aprobadas,
            data.rechazadas,
          ],
          backgroundColor: ["#1D417A", "#6BE58A", "#FF6660"],
          pointRadius: 7,
          pointBorderWidth: 2,
          borderWidth: 3,
        },
      ],
    };
  };

  const data = graphicData();

  return (
    <Card elevation={5} sx={{ borderRadius: "20px" }}>
      <CardContent>
        <Stack direction="row" spacing={2} alignItems="baseline">
          <Typography variant="h6">{t("amountcard.title")}</Typography>
          <TimeFilter />
        </Stack>
        <Divider sx={{ my: "1rem" }} />

        <Stack sx={{ height: "3rem", alignItems: "center", mt: "10px" }}>
          <Select
            value={selectedMonth}
            onChange={handleMonthChange}
            displayEmpty
          >
            <MenuItem value="" >
              {t("cardMonth.options")}
            </MenuItem>
            {renderMonthOptions()}
          </Select>
        </Stack>

        <Stack sx={{ height: "12rem", alignItems: "center", mt: "30px" }}>
          {isLoading && <div>Loading...</div>}
          {!isLoading && !isError && data && (
            <Line data={data} options={options} />
          )}
        </Stack>
      </CardContent>
    </Card>
  );
}
