import { Grid, Typography, Stack, Box, Divider } from "@mui/material";
import { useTranslation } from "react-i18next";
import ProductsTop from "../ProductsTop";
import AmountPerPeriodHours from "../AmountPerPeriodHours";
import AmountPerPeriodDays from "../AmountPerPeriodDays";

export default function TransactionsSummaryLayout() {
  const { t } = useTranslation();

  return (
    <Box className="content" sx={{ ml: { xs: "0", sm: "1rem" }, mt: "0" }}>
      <Typography component="div" variant="h4" fontWeight={600}>
        {t("transactionsResume.title")}
      </Typography>
      <Divider sx={{ my: "1rem" }} />
      <Grid container gap={"2rem"} alignItems="center" justifyContent="center">
        <Grid item xs={12}>
          <Stack direction={{ xs: "columns", md: "row" }} mt={2} gap={"3rem"}>
            {/* <ProductsTop /> */}
            <AmountPerPeriodDays />
          </Stack>
          <Stack direction={{ xs: "columns", md: "row" }} mt={6} gap={"3rem"}>
            <AmountPerPeriodHours />
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
}
