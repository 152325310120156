import {
  Suspense,
  useContext,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import SearchAppBar from "./components/common/AppBar";
import HomeLayout from "./components/layout/HomeLayout";
import ClientsAndAgentsLayout from "./components/layout/ClientsAndAgentsLayout";
import SettingsLayout from "./components/layout/SettingsLayout";
import TransactionsLayout from "./components/layout/TransactionsLayout";
import UsersManagementLayout from "./components/layout/UsersManagementLayout";
import SupportLayout from "./components/layout/SupportLayout";
import FooterBar from "./components/common/Footer";
import PageNotFound from "./components/common/PageNotFound";
import Loader from "./components/common/Loading";
import LoginLayout from "./components/layout/LoginLayout";
import CommercesByAccountLayout from "./components/layout/CommercesByAccountLayout";
import CreateAdminAccountLayout from "./components/layout/CreateAdminAccountLayout";
import CreateNewUserLayout from "./components/layout/CreateNewUserLayout";
import "./App.css";
import { Box } from "@mui/material";
import ProtectedRoutes from "./routes/ProtectedRoutes";
import { AuthContext } from "./context/AuthContext";
import TokenExpirationChecker from "./helpers/TokenExpirationChecker";
import SearchUserWithEmail from "./components/forget-password-components/SearchUserWithEmail";
import ChangeForgottenPasswordLayout from "./components/login-components/ChangeForgottenPasswordLayout";
import DetailedTransactionLayout from "./components/layout/DetailedTransactionLayout";
import CreateCommerceLayout from "./components/layout/CreateCommerceLayout";
import DocumentListLayout from "./components/layout/DocumentListLayout";
import PartiesTransactionLayout from "./components/layout/PartiesTransactionLayout";
import ForgetPasswordLayout from "./components/layout/ForgetPasswordLayout";
import CreateNewRoleLayout from "./components/layout/CreateNewRoleLayout";
import RoleDetailsLayout from "./components/layout/RoleDetailsLayout";
import RolesByUserLayout from "./components/layout/RolesByUserLayout";
import PlansManagementLayout from "./components/layout/PlansManagementLayout";
import PlanDetailsLayout from "./components/layout/PlanDetailsLayout";
import CreateNewPlanLayout from "./components/layout/CreateNewPlanLayout";
import SensitiveDataChangesLayout from "./components/layout/SensitiveDataChangesLayout";
import CreateNewTaxLayout from "./components/layout/CreateNewTaxLayout";
import TaxDetailsLayout from "./components/layout/TaxDetailsLayout";
import AlertsLayout from "./components/layout/AlertsLayout";
import { PublicRoutes } from "./routes/PublicRoutes";
import { ReviewProvider } from "./context/ReviewDocumentContext";
import { ReviewSensitiveChangeProvider } from "./context/ReviewSensiteChangeContext";
import SelectViaTwoFactorNotificaction from "./components/login-components/SelectViaTwoFactorNotificaction";
import VerificationCodeEmail from "./components/login-components/VerificationCodeEmail";
import ClientInfoForm from "./components/client-management-components/ClientInfoForm";
import AgentInfoForm from "./components/agent-management-components/AgentInfoForm";
import ConfirmEmail from "./components/login-components/ConfirmEmail";
import LinkNotificaction from "./components/login-components/LinkNotificaction";
import VerificationCodeEmailOTP from "./components/login-components/VerificationCodeEmailOTP";
import { PlanProvider } from "./context/PlanContext";
import UploadDocumentLayout from "./components/layout/UploadDocumentLayout";
import ListManagementLayout from "./components/layout/ListManagementLayout";
import ArlManagementLayout from "./components/layout/ArlManagementLayout";
import EpsManagementLayout from "./components/layout/EpsManagementLayout";
import CallsLayout from "./components/layout/CallsLayout";
import AccompanimentLayout from "./components/layout/AccompanimentLayout";
import MapsLayout from "./components/layout/MapsLayout";
import CompaniesLayout from "./components/layout/CompaniesLayout";
import ZonesLayout from "./components/layout/ZonesLayout";
import CreateNewZoneLayout from "./components/layout/CreateNewZoneLayout";
import NotificationsLayout from "./components/layout/NotificationsLayout";
import ChangePasswordLayout from "./components/layout/ChangePasswordLayout";
import ZoneDetailsLayout from "./components/layout/ZoneDetailsLayout";

import { AuthProvider } from "../src/context/AuthContext";
import CountryManagementLayout from "./components/layout/CountryManagementLayout";
import DepartmentManagementLayout from "./components/layout/DepartmentManagementLayout";
import CityManagementLayout from "./components/layout/CityManagementLayout";
import { ZoneProvider } from "./context/ZonesContext";
import AlertInfoForm from "./components/alert-management-components/AlertInfoForm";
import AlertDetailsLayout from "./components/layout/AlertDetailsLayout";
import CreateNewCompaniesLayout from "./components/layout/CreateNewCompaniesLayout";
import CompanyDetailsLayout from "./components/layout/CompanyDetailsLayout";
import { CompanyProvider } from "./context/CompanyContext";
import AccompanimentDetailsLayout from "./components/layout/AccompanimentDetailLayout";
import { AlertOptionsProvider } from "./context/AlertOptionsContext";
import TransactionsDetailLayout from "./components/layout/TransactionsDetailLayout";

function App() {
  const { isLoggedIn, setLoggedIn } = useContext(AuthContext);
  const [activeToken, setActiveToken] = useState(
    localStorage.getItem("refreshToken") || null
  );

  useEffect(() => {
    const token = activeToken;
    !token || token.trim() === "" ? setLoggedIn(false) : setLoggedIn(true);
  }, [activeToken]);

  const AppWrapper = ({ children }) => {
    const location = useLocation();
    useLayoutEffect(() => {
      document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
    return children;
  };

  const ReviewDocumentWrapper = ({ children }) => {
    return <ReviewProvider>{children}</ReviewProvider>;
  };

  const ReviewSensitiveChangesWrapper = ({ children }) => {
    return (
      <ReviewSensitiveChangeProvider>{children}</ReviewSensitiveChangeProvider>
    );
  };

  const PlanWrapper = ({ children }) => {
    return <PlanProvider>{children}</PlanProvider>;
  };

  const ZonesWrapper = ({ children }) => {
    return <ZoneProvider>{children}</ZoneProvider>;
  };

  const CompaniesWrapper = ({ children }) => {
    return <CompanyProvider>{children}</CompanyProvider>;
  };
  const AlertOptionsWrapper = ({ children }) => {
    return <AlertOptionsProvider>{children}</AlertOptionsProvider>;
  };

  return (
    <Suspense delayMs={500} fallback={<Loader />}>
      <Router basename="/">
        <AppWrapper>
          <SearchAppBar />
          <TokenExpirationChecker />
          <Box className={isLoggedIn && "App"} sx={{ ml: { xs: 0, sm: 6 } }}>
            <Routes>
              <Route path="forgetPassword" element={<SearchUserWithEmail />} />
              <Route
                path="changeForgottenPassword"
                element={<ChangeForgottenPasswordLayout />}
              />
              <Route exact path="/reset/:token" element={<PublicRoutes />}>
                <Route
                  path="/reset/:token"
                  element={<ForgetPasswordLayout />}
                />
              </Route>
              <Route exact path="/select_two_factor" element={<PublicRoutes />}>
                <Route
                  path="/select_two_factor"
                  element={<SelectViaTwoFactorNotificaction />}
                />
              </Route>
              <Route
                exact
                path="/password_recovery_link_send"
                element={<PublicRoutes />}
              >
                <Route
                  path="/password_recovery_link_send"
                  element={<LinkNotificaction />}
                />
              </Route>
              <Route exact path="/Confirm_email" element={<PublicRoutes />}>
                <Route path="/Confirm_email" element={<ConfirmEmail />} />
              </Route>

              <Route
                exact
                path="/verification_code_otp"
                element={<PublicRoutes />}
              >
                <Route
                  path="/verification_code_otp"
                  element={<VerificationCodeEmailOTP />}
                />
              </Route>

              <Route
                exact
                path="/verification_code_email"
                element={<PublicRoutes />}
              >
                <Route
                  path="/verification_code_email"
                  element={<VerificationCodeEmail />}
                />
              </Route>
              {!isLoggedIn ? (
                <Route exact path="/" element={<LoginLayout />} />
              ) : (
                <Route
                  exact
                  path="/"
                  element={<ProtectedRoutes isAllowed={true} />}
                >
                  <Route exact path="/" element={<HomeLayout />} />
                  <Route
                    path="clients&Agents"
                    element={<ClientsAndAgentsLayout />}
                  />
                  <Route path="settings" element={<SettingsLayout />} />
                  <Route
                    path="changePassword"
                    element={
                      <AuthProvider>
                        <ChangePasswordLayout />
                      </AuthProvider>
                    }
                  />
                  <Route path="transactions" element={<TransactionsLayout />} />
                  <Route
                    path="transactions/detail/:elementId"
                    element={<TransactionsDetailLayout />}
                  />
                  <Route
                    path="accompaniment"
                    element={<AccompanimentLayout />}
                  />
                  <Route
                    path="accompaniment/detail/:elementId"
                    element={<AccompanimentDetailsLayout />}
                  />
                  <Route path="maps" element={<MapsLayout />} />
                  <Route
                    path="companies"
                    element={
                      <CompanyProvider>
                        <CompaniesLayout />
                      </CompanyProvider>
                    }
                  />
                  <Route
                    path="companies/new"
                    element={
                      <CompanyProvider>
                        <CreateNewCompaniesLayout />
                      </CompanyProvider>
                    }
                  />
                  <Route
                    path="companyDetails/:companyId"
                    element={<CompanyDetailsLayout />}
                  />
                  <Route
                    path="editCompany/:companyId"
                    element={
                      <CompanyProvider>
                        <CreateNewCompaniesLayout />
                      </CompanyProvider>
                    }
                  />

                  <Route
                    path="zones"
                    element={
                      <ZonesWrapper>
                        <ZonesLayout />
                      </ZonesWrapper>
                    }
                  />

                  {["/zones/new", "/availableZones/new"].map((path, index) => {
                    return (
                      <Route
                        path={path}
                        element={
                          <ZonesWrapper>
                            <CreateNewZoneLayout />
                          </ZonesWrapper>
                        }
                        key={index}
                      />
                    );
                  })}

                  <Route
                    path="zones/details/:zoneId"
                    element={
                      <ZonesWrapper>
                        <ZoneDetailsLayout />
                      </ZonesWrapper>
                    }
                  />
                  <Route
                    path="zones/edit/:zoneId"
                    element={
                      <ZonesWrapper>
                        <CreateNewZoneLayout />
                      </ZonesWrapper>
                    }
                  />
                  <Route
                    path="notifications"
                    element={<NotificationsLayout />}
                  />
                  <Route
                    path="commercesByAccount/:id"
                    element={<CommercesByAccountLayout />}
                  />
                  <Route
                    path="transactionsByAccount/:accountId"
                    element={<TransactionsLayout />}
                  />
                  <Route
                    path="transactionsByShop/:shopId"
                    element={<TransactionsLayout />}
                  />
                  <Route
                    path="transactions/:id"
                    element={<DetailedTransactionLayout />}
                  />
                  <Route
                    path="sensitiveChanges"
                    element={
                      <ReviewSensitiveChangesWrapper>
                        <SensitiveDataChangesLayout />
                      </ReviewSensitiveChangesWrapper>
                    }
                  />
                  <Route
                    path="usersManagement"
                    element={<UsersManagementLayout />}
                  />
                  <Route
                    path="listManagement"
                    element={
                      <ZoneProvider>
                        <ListManagementLayout />
                      </ZoneProvider>
                    }
                  />
                  <Route
                    path="createNewRole"
                    element={<CreateNewRoleLayout />}
                  />
                  <Route
                    path="editRole/:roleId"
                    element={<CreateNewRoleLayout />}
                  />
                  <Route
                    path="roleDetails/:roleId"
                    element={<RoleDetailsLayout />}
                  />
                  <Route
                    path="rolesByUser/:userId"
                    element={<RolesByUserLayout />}
                  />
                  <Route
                    path="createAdminAccount"
                    element={<CreateAdminAccountLayout />}
                  />
                  <Route
                    path="arlManagement"
                    element={<ArlManagementLayout />}
                  />
                  <Route
                    path="epsManagement"
                    element={<EpsManagementLayout />}
                  />
                  <Route
                    path="countryManagement"
                    element={<CountryManagementLayout />}
                  />
                  <Route
                    path="departmentManagement"
                    element={<DepartmentManagementLayout />}
                  />
                  <Route
                    path="cityManagement"
                    element={<CityManagementLayout />}
                  />
                  <Route path="alertManagement" element={<AlertInfoForm />} />
                  <Route
                    path="editAdminAccount/:accountId"
                    element={<CreateAdminAccountLayout />}
                  />
                  <Route path="clientAccount" element={<ClientInfoForm />} />
                  <Route path="AgentAccount" element={<AgentInfoForm />} />
                  <Route
                    path="updateAdminUser"
                    element={<CreateNewUserLayout />}
                  />
                  <Route
                    path="AdminAccount"
                    element={<CreateNewUserLayout />}
                  />
                  <Route
                    path="createNewCommerce/:accountId"
                    element={<CreateCommerceLayout />}
                  />
                  <Route
                    path="resumeCommerceRegister/:shopId"
                    element={<CreateCommerceLayout />}
                  />
                  <Route
                    path="editCommerce/:shopId"
                    element={<CreateCommerceLayout />}
                  />
                  <Route
                    path="documentList/:userId"
                    element={
                      <ReviewDocumentWrapper>
                        <DocumentListLayout />
                      </ReviewDocumentWrapper>
                    }
                  />
                  <Route
                    path="uploadDocuments/:userId"
                    element={<UploadDocumentLayout />}
                  />
                  <Route
                    path="editDocument/:userId"
                    element={<UploadDocumentLayout />}
                  />
                  <Route
                    path="transactionParties/:id"
                    element={<PartiesTransactionLayout />}
                  />
                  <Route
                    path="plansManagement"
                    element={
                      <PlanWrapper>
                        <PlansManagementLayout />
                      </PlanWrapper>
                    }
                  />
                  <Route
                    path="planDetails/:planId"
                    element={<PlanDetailsLayout />}
                  />
                  <Route
                    path="createNewPlan"
                    element={
                      <PlanWrapper>
                        <CreateNewPlanLayout />
                      </PlanWrapper>
                    }
                  />
                  <Route
                    path="editPlan/:planId"
                    element={
                      <PlanWrapper>
                        <CreateNewPlanLayout />
                      </PlanWrapper>
                    }
                  />
                  <Route path="createNewTax" element={<CreateNewTaxLayout />} />
                  <Route
                    path="editTax/:taxId"
                    element={<CreateNewTaxLayout />}
                  />
                  <Route
                    path="taxDetails/:taxId"
                    element={<TaxDetailsLayout />}
                  />
                  <Route path="support" element={<SupportLayout />} />
                  <Route
                    path="alertDetails/:alertId"
                    element={
                      <AlertOptionsWrapper>
                        <AlertDetailsLayout />
                      </AlertOptionsWrapper>
                    }
                  />
                  <Route
                    path="alerts"
                    element={
                      <AlertOptionsWrapper>
                        <AlertsLayout />
                      </AlertOptionsWrapper>
                    }
                  />
                  <Route path="calls" element={<CallsLayout />} />
                </Route>
              )}
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </Box>
          <FooterBar />
        </AppWrapper>
      </Router>
    </Suspense>
  );
}

export default App;
