import { Card, CardContent, Divider, Typography, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { getToken } from "../../utils/GetToken";
import { GetTransactionsService } from "../../services/GetTransactionsService";
import { Doughnut } from "react-chartjs-2";
import PaidIcon from "@mui/icons-material/Paid";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

export default function ResumeCard() {
  const { t } = useTranslation();
  const token = getToken();

  const getTransactions = async () => {
    const response = await GetTransactionsService(token);
    console.log(response.data);
    return response.data;
  };

  const {
    data: response,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["transactions"],
    queryFn: () => getTransactions(),
  });

  const transactions = response?.data || [];

  const calculateTotals = () => {
    if (!transactions) {
      return {
        madePercentage: 0,
        approvedPercentage: 0,
        rejectedPercentage: 0,
      };
    } 

    const keys = Object.keys(transactions);
    const totalMade = keys.reduce((total, key) => total + transactions[key].realizadas,0);
    const totalApproved = keys.reduce((total, key) => total + transactions[key].aprobadas,0);
    const totalRejected = keys.reduce((total, key) => total + transactions[key].rechazadas,0);

    const totalTransactions = totalMade + totalApproved + totalRejected;
    const madePercentage = (totalMade / totalTransactions) * 100;
    const approvedPercentage = (totalApproved / totalTransactions) * 100;
    const rejectedPercentage = (totalRejected / totalTransactions) * 100;

    return {
      totalMade,
      totalApproved,
      totalRejected,
      madePercentage,
      approvedPercentage,
      rejectedPercentage,
    };
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: "bottom",
      },
    },
  };

  return (
    <Card elevation={5} sx={{ borderRadius: "20px" }}>
      <CardContent>
        <Typography variant="h6">{t("resumecard.title")}</Typography>
        <Divider sx={{ marginBlock: "1rem" }} />
        <Typography variant="h6" mb={3}>
          {t("resumecard.subtitle-2")}
        </Typography>
        <Stack direction="column" alignItems="center" ml="4rem">
          <Stack direction="row" alignItems="center" sx={{ width: "80%" }}>
            <PaidIcon sx={{ color: "primary.light", mr: "0.3rem" }} />
            <Typography variant="subtitle2" color={"primary.light"}>
              <Typography variant="subtitle2" color={"primary.light"}>
                {isLoading ? "Loading..." : calculateTotals().totalMade}{" "}
                {t("made")}
              </Typography>
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center" sx={{ width: "80%" }}>
            <CheckCircleIcon color="success" sx={{ mr: "0.3rem" }} />
            <Typography variant="subtitle2" color={"success.main"}>
              {isLoading ? "Loading..." : calculateTotals().totalApproved}{" "}
              {t("approved")}
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center" sx={{ width: "80%" }}>
            <CancelIcon color="error" sx={{ mr: "0.3rem" }} />
            <Typography variant="subtitle2" color={"error.main"}>
              {isLoading ? "Loading..." : calculateTotals().totalRejected}{" "}
              {t("rejected")}
            </Typography>
          </Stack>
        </Stack>

        <Stack sx={{ height: "12rem", alignItems: "center", mt: "30px" }}>
          <Doughnut
            data={{
              datasets: [
                {
                  data: [
                    calculateTotals().madePercentage,
                    calculateTotals().approvedPercentage,
                    calculateTotals().rejectedPercentage,
                  ],
                  backgroundColor: ["#1D417A", "#6BE58A", "#FF6660"],
                  borderWidth: 2,
                },
              ],
            }}
            options={options}
          />
        </Stack>
      </CardContent>
    </Card>
  );
}
