import { useState, useEffect } from "react";
import {
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";

import { useTranslation } from "react-i18next";

const HandleAccompanimentActions = ({ elementId }) => {
  const [clickSeeMore, setClickSeeMore] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleSeeMore = () => {
    setClickSeeMore(true);
    handleClose();
  };
  useEffect(() => {
    if (clickSeeMore) {
      navigate(`/accompaniment/detail/${elementId}`);
    }
  }, [clickSeeMore]);
  return (
    <>
      <IconButton onClick={handleClick} size="small">
        <MenuIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        keepMounted
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 170,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          },
        }}
      >
        <MenuItem onClick={handleSeeMore}>
          <ListItemIcon>
            <InfoIcon />
          </ListItemIcon>
          <ListItemText>{t("accompaniment.header.details")}</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};

const AccompanimentColumns = () => {
  const { t } = useTranslation();
  const currentLanguage = localStorage.getItem("i18nextLng");

  const columns = [
    {
      field: "actions",
      type: "actions",
      width: 80,
      renderCell: (params) => (
        <HandleAccompanimentActions elementId={params.row.id} />
      ),
    },
    {
        field: "name",
        headerName: 'Name',
        sortable: true,
        width: 250,
    },
    {
        field: "status",
        headerName:  t("accountList.headers-3"),
        sortable: false,
        width: 250,
    },
    {
      field: "createdAt",
      headerName: t("sensitiveChangesList.header-8"),
      sortable: false,
      width: 250,
    },
    {
      field: "startDate",
      headerName: t("accompaniment.header.start_date"),
      sortable: false,
      width: 250,
    },
    {
      field: "endDate",
      headerName: t("accompaniment.header.end_date"),
      sortable: false,
      width: 250,
    },
  ];

  return columns;
};

export default AccompanimentColumns;