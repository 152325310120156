import { Card, CardContent, Divider, Typography, Stack, FormControl, InputLabel, Select } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import TimeFilter from "../common/TimeFilter";
import MovingIcon from "@mui/icons-material/Moving";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import { getToken } from "../../utils/GetToken";
import { GetAgentStatsServices } from "../../services/GetAgentStatsServices";
import { useQuery, useMutation } from "@tanstack/react-query";

export default function AgentsAccountsResume() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const token = getToken();
  const [selectedMonth, setSelectedMonth] = useState("");

  const getAgentStats = async () => {
    const response = await GetAgentStatsServices(token);
    console.log(response.data);
    return response.data;
  };

  const {
    data: response,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["agentStats"],
    queryFn: () => getAgentStats(),
  });

  const agentsStats = response?.data || [];
  const totalAgents = agentsStats.reduce((total, agent) => total + agent.count, 0);
  const percentage = (totalAgents * totalAgents) / 100;

  const availableMonths = [...new Set(agentsStats.map((agent) => agent.month))];

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  const getCountForSelectedMonth = () => {
    const selectedUserStats = agentsStats.find(
      (agent) => agent.month === selectedMonth
    );
    return selectedUserStats ? selectedUserStats.count : 0;
  };



  return (
    <Card elevation={5} sx={{ borderRadius: "20px" }}>
      <CardContent>
        <Stack direction="row" spacing={2} alignItems="baseline">
          <Typography variant="h6">{t("accountsCard.title")}</Typography>
          <TimeFilter />
        </Stack>
        <Divider sx={{ my: "1rem" }} />
        <Typography>{t("accountsCard.subtitle-2")}</Typography>
        <Stack direction="row" spacing={1} alignItems="baseline" my={1}>
          <Typography variant="h5" fontWeight={600}>
          {isLoading || isError ? "-" : totalAgents}
          </Typography>
          <Typography variant="p">{t("registered")}</Typography>
        </Stack>
        <Stack direction="row" spacing={0.5} alignItems="center">
          <MovingIcon color="success" />
          <Typography variant="subtitle2" color={"success.main"}>
           ({percentage}%)
          </Typography>
        </Stack>
        <Divider sx={{ my: "1rem" }} />
        <Typography>{t("accountsCard.subtitle-6")}</Typography>
        <Stack direction="row" spacing={2} alignItems="center" mt="1rem">
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <InputLabel>{t("months")}</InputLabel>
            <Select
              value={selectedMonth}
              onChange={handleMonthChange}
              displayEmpty
              label={t("months")}
            >
              {availableMonths.map((month) => (
                <MenuItem key={month} value={month}>
                  {t("months") + " " + t(month)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Mostrar el valor de count para el mes seleccionado */}
          {selectedMonth && (
            <Stack direction="row" spacing={0.5} alignItems="center">
              <GroupAddIcon color="success" />
              <Typography variant="h5" color={"success.main"}>
                {isLoading || isError ? "-" : getCountForSelectedMonth()}
              </Typography>
            </Stack>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
}
