import { useMemo, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";
import {
  Card,
  CardContent,
  Divider,
  Typography,
  Stack,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import TimeFilter from "../common/TimeFilter";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const scores = [
  120000, 0, 0, 1574823, 42423, 0, 0,
];
const labels = ["Lun", "Mar", "Mie", "Jue", "Vie", "Sab", "Dom"];

const options = {
  fill: true,
  responsive: true,
  scales: {
    y: {
      min: 0,
    },
  },
  plugins: {
    legend: {
      display: false,
    },
  },
};

export default function AmountPerPeriodDays() {
  const { t } = useTranslation();

  const theme = useTheme();

  const data = useMemo(function () {
    return {
      datasets: [
        {
          label: "Monto",
          data: scores,
          tension: 0.4,
          borderColor: theme.palette.primary.light,
          pointRadius: 4,
          pointBackgroundColor: theme.palette.primary.main,
          backgroundColor: "transparent",
        },
      ],
      labels,
    };
  }, []);

  return (
    <Card elevation={5} sx={{ borderRadius: "20px", minWidth: {sm: "50%", md: "100%"} }}>
      <CardContent>
        <Stack direction="row" spacing={2} alignItems="baseline">
          <Typography variant="h6">{t("amountcardDays.title")}</Typography>
        </Stack>
        <Divider sx={{ my: "1rem" }} />
        <Typography>Total</Typography>
        <Stack direction="row" spacing={1} alignItems="top" mb={2}>
          <Typography variant="h5" fontWeight={600}>$1'737.246</Typography>
          <Typography variant="p">COP</Typography>
        </Stack>
        <Line data={data} options={options} />
      </CardContent>
    </Card>
  );
}
