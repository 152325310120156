import { useState } from "react";
import { Grid, Typography, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useMenu } from "../../context/MenuOpenContext";
import { Content } from "../../utils/Content";
import { useTranslation } from "react-i18next";


export default function NotificationsLayout() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isOpen } = useMenu();


  return (
    <Content open={isOpen}>
      <Grid container direction="column">
        <Grid item xs paddingX={"20px"}>
          {/* <Snackbar
            open={showAlertApproved}
            autoHideDuration={6000}
            onClose={handleCloseDocApproved}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert severity="success">{t("approveDoc.message")}</Alert>
          </Snackbar>
          <Snackbar
            open={showAlertRejected}
            autoHideDuration={6000}
            onClose={handleCloseDocRejected}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert severity="info">{errorMessage}</Alert>
          </Snackbar> */}
          <Typography
            variant="h4"
            color={"primary"}
            textAlign={"flex-start"}
            sx={{ textTransform: "uppercase" }}
            fontWeight={800}
          >
            {t("navlink.notifications")}
          </Typography>
          <Box my={2}>
            <Typography variant="body1" textAlign={"flex-start"}>

            </Typography>
          </Box>
        </Grid>
        <Box
          mt={2}
          sx={{
            height: 400,
            width: "100%",
            paddingInline: { xs: 0, sm: "1rem" },
          }}
        >

        </Box>
      </Grid>
    </Content>
  );
}
