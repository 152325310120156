export const zoneEditMapping = (data) => {
  const zoneMapped = {
    id: data?.id,
    name: data?.name,
    location: data?.location,
    country: data?.country,
    department: filteredLocation(data?.department),
    city: filteredLocation(data?.city),
    center: data?.geo_area?.coordinates[0][0],
    coordinates: data?.geo_area?.coordinates[0],
  };
  return zoneMapped;
};

const filteredLocation = (location) => {
  const { id, name, active } = location;
  return { id, name, active };
};
