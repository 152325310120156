import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { GetSensitiveDataChangesService } from "../../services/SensitiveData/GetSensitiveDataChangesService";
import { getToken } from "../../utils/GetToken";
import SensitiveChangesColumns from "../../utils/TableHeaders/SensitiveChangesHeaders";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import AccompanimentColumns from "./AccompanimentHeaders";
import { getAccompanimentListService } from "../../services/Accompaniment/GetAccompanimentListService";

export default function AccompanimentList() {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    getAccompanimentList();
  }, []);

  const getAccompanimentList = async () => {
    try {
      const token = await getToken();
      if (token) {
        const data = await getAccompanimentListService(token);
        const mappedData = mapData(data);
        setRows(mappedData);
        console.log(data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const mapData = (data) =>
    data?.map((item) => {
      const createdAt = dayjs(item.createdAt).format("DD/MM/YYYY H:m:s A");
      const startDate = !!item?.startDate ? dayjs(item.startDate).format("DD/MM/YYYY H:m:s A") : "";
      const endDate = !!item?.endDate ? dayjs(item?.endDate).format("DD/MM/YYYY H:m:s A") : "";
      const mappedItem = {
        id: item.id,
        name: item?.type?.name ?? '',
        status: item?.state ?? '',
        approverName: item.approverName,
        createdAt: createdAt,
        startDate,
        endDate,
      };
      return mappedItem;
    }) ?? [];

  return (
    <>
      <DataGrid
        rows={rows}
        columns={AccompanimentColumns()}
        loading={loading}
        disableRowSelectionOnClick
        hideFooter
        sx={{
          boxShadow: "0 1px 12px rgba(149, 157, 165, 0.2)",
          borderRadius: "25px",
          borderColor: "transparent",
          backgroundColor: "white",
          paddingX: "1.5rem",
          "& .MuiDataGrid-cell": {
            borderColor: "gray.light",
          },
          "& .MuiDataGrid-cell:hover": {
            color: "secondary.main",
          },
          "& .super-app-theme--header": {
            backgroundColor: "white",
          },
        }}
      />
    </>
  );
}
